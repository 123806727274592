import {
  INITIAL_CHECKOUT_ADDONS,
  INITIAL_CHECKOUT_DATA,
  UPDATE_CHECKOUT_ADDONS_DATA,
  UPDATE_CHECKOUT_DATA,
  UPDATE_CHECKOUT_NESTED_DATA,
} from '../actions/types';

const initialState = {
  chosenCheckoutType: '',
  chosenCheckoutIndex: 0,
  carId: '',
  subscribeToNewsletter: true,
  contactDetails: {
    firstName: '',
    lastName: '',
    address1: '',
    addressCO: '',
    zipCode: '',
    city: '',
    socialSecurityNumber: '',
    email: '',
    phone: '',
    messageToSeller: '',
    isCompany: false,
    companyName: '',
    orgNumber: '',
  },
  sellerDetails: {
    name: '',
    phone: '',
    email: '',
    siteName: '',
  },
  exchangeCar: {
    hasExchangeCar: false,
    regNo: '',
    mileage: '',
  },
  addons: [],
  privateLeasingAddons: [],
  checkoutChanges: {
    financeChanged: false,
    exchangeCarChanged: false,
    addonsChanged: false,
    summaryChanged: false,
    payInfoChanged: false,
  },
  meta: {
    siteAddress: '',
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INITIAL_CHECKOUT_DATA:
      return {
        ...state,
        ...action.payload.checkout,
      };

    case INITIAL_CHECKOUT_ADDONS:
      return {
        ...state,
        ...action.payload.checkout,
      };

    case UPDATE_CHECKOUT_DATA:
      return {
        ...state,
        ...action.payload.checkout,
      };

    case UPDATE_CHECKOUT_NESTED_DATA:
      return {
        ...state,
        ...action.payload.checkout,
      };

    case UPDATE_CHECKOUT_ADDONS_DATA:
      return {
        ...state,
        ...action.payload.checkout,
      };

    default:
      return state;
  }
}
