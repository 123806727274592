import React, { useEffect } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import { createScriptTagsInHeadFromHTML } from './utils';
import { getPageType } from '../../Layout';
import { useLocation } from 'react-router-dom';
import BaseMetaTags from '../BaseMetaTags';
import { getTranslation } from '../../utils/getTranslation';

const MetaTags = ({ route, imagePlaceholder, sitecoreContext }) => {
  if (sitecoreContext.pageEditing) return null;
  const location = useLocation();

  useEffect(() => {
    const hasScriptTag = document.getElementsByTagName('noscript')[1];

    // Check if there is a second "<noscript>". That means the first one is an GTM iframe already.
    // This is necessary when switching routes, so it won't add another GTM code snippet.
    if (!hasScriptTag) {
      // Insert "<noscript>" to beginning of body tag.
      document.body.insertAdjacentHTML(
        'afterbegin',
        route.fields.gtmModule.value && route.fields.gtmModule.value
      );
    }

    if (route?.fields?.scriptTags?.value) {
      createScriptTagsInHeadFromHTML(route?.fields?.scriptTags?.value);
    }
  }, []);
  const pageTitle = getTranslation('PageTitle', 'Bavaria');

  return (
    <>
      {getPageType(location.pathname) !== 'Site Detail Page' &&
      getPageType(location.pathname) !== 'Product Detail Page' ? (
        <>
          <Helmet>
            <title>{`${route?.fields?.pageTitle?.value} | ${pageTitle}`}</title>
            <meta name="title" content={`${route?.fields?.metaTitle?.value} | ${pageTitle}`} />
            <meta
              property="og:title"
              content={`${route?.fields?.metaTitle?.value} | ${pageTitle}`}
            />
          </Helmet>
          <BaseMetaTags route={route} imagePlaceholder={imagePlaceholder} />
        </>
      ) : null}
    </>
  );
};

export default withSitecoreContext()(MetaTags);
