import React, { useEffect, useState } from 'react';
import {
  Placeholder,
  VisitorIdentification,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import deepEqual from 'deep-equal';
import { useLocation } from 'react-router-dom';
// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.

import './assets/styles/app.scss';
import ogImagePlaceholder from './assets/img/og-image-placeholder.png';
import MetaTags from './components/MetaTags';
import loadable from '@loadable/component';
import i18n from 'i18next';
import { loadCSSInHead } from './utils/loadCSSInHead';
import { isSSR } from '@hedinbil/hedin-ui/dist/utils/isSSR';

const LayoutPlaceholderErrorComponent = loadable(() =>
  import('./components/LayoutPlaceholderErrorComponent')
);
/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

export const getPageType = (path) => {
  const carShowPage = new RegExp('\\/bilar\\/[a-zA-Z0-9]+\\/', 'gm');
  const siteShowPage = new RegExp('\\/hitta-oss\\/[a-zA-Z0-9]+\\/', 'gm');
  const siteAltShowPage = new RegExp('\\/anlaggningar\\/[a-zA-Z0-9]+\\/', 'gm');

  if (carShowPage.test(path)) {
    return 'Product Detail Page';
  }

  // TODO: check if below is the one to use or not?
  if (siteShowPage.test(path)) {
    return 'Site Detail Page';
  }

  if (siteAltShowPage.test(path)) {
    return 'Site Detail Page';
  }

  return 'Page';
};

const getEnvironmentInfo = () => {
  switch (window.location.host) {
    case 'bavariano-test.hedinbil.se':
      return 'Test';

    case 'bavariano-stage.hedinbil.se':
      return 'Quality Assurance';

    case 'bavariano-preprod.hedinbil.se':
      return 'Production';

    case 'www.bavaria.no':
      return 'Production';

    case 'bavaria.no':
      return 'Production';

    default:
      return 'Development';
  }
};

const Layout = (props) => {
  const { route, sitecoreContext } = props;
  const location = useLocation();
  const [routeChangeCount, setRouteChangeCount] = useState(0);

  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  const customCSSUrl = i18n.t('CustomCSSUrl') ? i18n.t('CustomCSSUrl') : null;

  useEffect(() => {
    // Wait 200ms for nested component to have updated document.title as sometimes it could go too quick.
    setTimeout(() => {
      // If first load then it's a "Page Load" otherwise "Virtual Page Load" for all React router changes.
      const pageInfoTrigger = routeChangeCount === 0 ? 'Page Load' : 'Virtual Page Load';

      // Check if 404 hero is on page, if so then send a 404 event.
      const has404Hero = document.getElementById('page-not-found-hero');
      const is404Page = has404Hero ? '404' : '200';

      const environmentName = getEnvironmentInfo();
      const isProduction = environmentName === 'Production';

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'Page Impression',
        environmentInfo: {
          name: environmentName,
          platform: 'Sitecore',
          isProduction: isProduction,
        },
        pageInfo: {
          url: window.location.href,
          canonicalUrl: window.location.origin + window.location.pathname,
          virtualUrlPath: location.pathname,
          title: document.title,
          type: getPageType(location.pathname),
          trigger: pageInfoTrigger,
          httpStatusCode: is404Page,
        },
      });

      setRouteChangeCount(routeChangeCount + 1);
    }, 200);

    // As Experience Editor seems to not include <Helmet> (BaseMetaTags & MetaTags)
    // we have to create a custom solution to fully make our sites beautiful in both the editor and frontend.
    if (isEditing && customCSSUrl && customCSSUrl !== 'CustomCSSUrl' && !isSSR()) {
      loadCSSInHead(customCSSUrl);
    }
  }, [route]);

  return (
    <React.Fragment>
      <MetaTags route={route} imagePlaceholder={ogImagePlaceholder} />
      <VisitorIdentification />
      <div className="page-wrapper">
        <Placeholder name="jss-header" rendering={route} />

        {/* root placeholder for the app, which we add components to using route data */}

        <Placeholder
          name="jss-main"
          rendering={route}
          errorComponent={(props) => (
            <LayoutPlaceholderErrorComponent isEditing={isEditing} {...props} />
          )}
        />

        <Placeholder name="jss-footer" rendering={route} />
      </div>
    </React.Fragment>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  return !!deepEqual(prevProps.route, nextProps.route);
};

export default React.memo(withSitecoreContext()(Layout), propsAreEqual);
