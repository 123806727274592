import React from 'react';
import i18n from 'i18next';
import Helmet from 'react-helmet';

const Index = ({ route, imagePlaceholder, children }) => {
  const windowIsUndefined = typeof window === 'undefined';
  const customCSSUrl = i18n.t('CustomCSSUrl') ? i18n.t('CustomCSSUrl') : null;
  const customFavicon = i18n.t('Favicon') ? i18n.t('Favicon') : null;

  const canonicalURL = route.fields.canonicalURL.value;
  const metaURL = windowIsUndefined
    ? '__SSR_DYNAMIC_HOST__'
    : window.location.origin + window.location.pathname;

  const usedCanonicalURL = canonicalURL !== '' ? canonicalURL : metaURL;
  const ogURL = route?.fields?.ogUrl?.value;

  const metaFields = [];

  if (route?.fields?.metadataKeyValueFields?.value) {
    const splitKeyValues = route?.fields?.metadataKeyValueFields?.value?.split('&');

    if (splitKeyValues) {
      (splitKeyValues || []).forEach((meta) => {
        const metaValue = meta.split('=');
        metaFields.push({ key: metaValue[0], value: metaValue[1] });
      });
    }
  }

  return (
    <Helmet>
      {(metaFields || []).map((item, index) => (
        <meta key={index} name={item.key} content={item.value} />
      ))}
      {!route.fields.noIndex.value ? '' : <meta name="robots" content="noindex" />}
      <link href={usedCanonicalURL} rel="canonical" />
      <meta property="og:type" content={route.fields.ogType.value || 'website'} />
      <meta property="og:url" content={ogURL} />
      <meta
        property="og:image"
        content={(route.fields.ogImage.value && route.fields.ogImage.value.src) || imagePlaceholder}
      />
      <meta name="description" content={route.fields.metaDescription.value} />
      <meta property="og:description" content={route.fields.metaDescription.value} />
      {customCSSUrl && customCSSUrl !== 'CustomCSSUrl' && (
        <link rel="stylesheet" href={customCSSUrl} />
      )}
      {customFavicon && customFavicon !== 'Favicon' && (
        <link rel="shortcut icon" href={customFavicon + '.ico'} />
      )}
      {children ? children : null}
    </Helmet>
  );
};

export default Index;
