import i18n from 'i18next';

/**
 * Gets the translation for a given text, with an optional default value.
 * Uses the key added on 'translate-' to look in Sitecore dictionary.
 * @param {string} text The text to translate.
 * @param {string} defaultValue The optional default value if translation is not found.
 * @returns {string} The translated text or the default value.
 */
export const getTranslation = (text, defaultValue) => {
  // Formulate the key for translation based on the input text.
  const formattedText = 'translate-' + (text || '').toLowerCase();

  // Check if a translation exists, if yes, return the translation.
  // If no translation, prioritize returning default value,
  // followed by the original text.
  return i18n.exists(formattedText) ? i18n.t(formattedText) : defaultValue ?? (text || '');
};
