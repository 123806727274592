import {
  INITIAL_CAR_FILTERS,
  INITIAL_SITE_FILTERS,
  INITIAL_ARTICLE_FILTERS,
  UPDATE_CARS,
  UPDATE_CARS_TIMESTAMP,
  UPDATE_TOTAL_COUNT,
  UPDATE_SITES,
  UPDATE_ARTICLES,
  UPDATE_CAR_FILTERS,
  UPDATE_SITE_FILTERS,
  UPDATE_ARTICLE_FILTERS,
  UPDATE_TIMES_SEARCHED_FILTERS,
  UPDATE_CURRENT_CAR,
  UPDATE_ALL_DYNAMIC_FILTERS,
  UPDATE_CURRENT_QUERY,
  RANGE_FILTERS_MIN_MAX_VALUES,
} from '../actions/types';

const initialState = {
  carFilters: [],
  cars: [],
  sites: [],
  articles: [],
  siteFilters: [],
  timesSearched: 0,
  currentCar: null,
  currentQuery: '',
  timeStamp: null,
  totalCount: {
    sites: 0,
    cars: 0,
    articles: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RANGE_FILTERS_MIN_MAX_VALUES:
      return {
        ...state,
        rangeValues: [...action.payload.rangeValues],
      };

    case INITIAL_CAR_FILTERS:
      return {
        ...state,
        carFilters: action.payload.carFilters,
        defCarFilters: [...action.payload.carFilters],
      };

    case UPDATE_CARS:
      return {
        ...state,
        cars: action.payload.cars,
      };

    case UPDATE_CARS_TIMESTAMP:
      return {
        ...state,
        timeStamp: action.payload.timeStamp,
      };

    case UPDATE_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload.totalCount,
      };

    case UPDATE_SITES:
      return {
        ...state,
        sites: action.payload.sites,
      };

    case UPDATE_ARTICLES:
      return {
        ...state,
        articles: action.payload.articles,
      };

    case UPDATE_ALL_DYNAMIC_FILTERS:
      return {
        ...state,
        [action.payload.filterType]: action.payload[action.payload.filterType],
      };

    case INITIAL_SITE_FILTERS:
      return {
        ...state,
        siteFilters: action.payload.siteFilters,
        defSiteFilters: [...action.payload.siteFilters],
      };

    case INITIAL_ARTICLE_FILTERS:
      return {
        ...state,
        articleFilters: action.payload.articleFilters,
        defArticleFilters: [...action.payload.articleFilters],
      };

    case UPDATE_CAR_FILTERS:
      return {
        ...state,
        carFilters: action.payload.carFilters,
      };

    case UPDATE_SITE_FILTERS:
      return {
        ...state,
        siteFilters: action.payload.siteFilters,
        currentQuery: action.payload.currentQuery,
      };

    case UPDATE_ARTICLE_FILTERS:
      return {
        ...state,
        articleFilters: action.payload.articleFilters,
        currentQuery: action.payload.currentQuery,
      };

    case UPDATE_CURRENT_QUERY:
      return {
        ...state,
        currentQuery: action.payload.currentQuery,
      };

    case UPDATE_TIMES_SEARCHED_FILTERS:
      return {
        ...state,
        timesSearched: action.payload.timesSearched,
      };

    case UPDATE_CURRENT_CAR:
      return {
        ...state,
        currentCar: action.payload.currentCar,
      };

    default:
      return state;
  }
}
