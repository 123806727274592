import { datadogLogs } from '@datadog/browser-logs';
import { sitecoreApiHost } from '../../../temp/config';

const getEnv = () => {
  if (sitecoreApiHost.includes('stage')) {
    return 'staging';
  }
  if (sitecoreApiHost.includes('prod')) {
    return 'production';
  }

  return 'production';
};

datadogLogs.init({
  clientToken: 'pubbf9a0b935ec4c7089c54700906ba22ac',
  site: 'datadoghq.eu',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: 'fe-bavaria-no',
  env: getEnv(),
});

export default datadogLogs;
