/**
 * Function for inserting and evaluating HTML including script tags.
 * @param {String} html
 * @param {HTMLElement} parentEl
 *
 */
export const createScriptTagsInHeadFromHTML = (html, parentEl = document.head) => {
  // Create a fake element
  const el = document.createElement('div');
  el.innerHTML = html;

  Array.from(el.querySelectorAll('script')).forEach((oldScript) => {
    const newScript = document.createElement('script');

    Array.from(oldScript.attributes).forEach((attr) =>
      newScript.setAttribute(attr.name, attr.value)
    );

    newScript.appendChild(document.createTextNode(oldScript.innerHTML));
    parentEl.appendChild(newScript);
  });

  // To prevent memory leaks
  if (el) {
    el.remove();
  }
};

export const removeScriptTagsFromHTML = (html) => {
  const el = document.createElement('div');
  el.innerHTML = html;

  el.querySelectorAll('script').forEach((scriptEl) => scriptEl?.remove());
  const result = el.innerHTML;

  // To prevent memory leaks
  if (el) {
    el.remove();
  }

  return result;
};
